//@ts-check
import React, { useState } from 'react';
import { Alert, Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import HttpHelper from '../../../../Helpers/HttpHelper';
import LoadingDots from './../../../../LoadingAnimations/LoadingDots/LoadingDots';

async function populateData() {
  const data = await HttpHelper.get('api/advisor/sharedattributes');
  return data;
}

// NOTE: This is also enforced on the server so any changes made here must also be made elsewhere
const attributesSectionLimit = 10;

export const AdvisorEditAttributes = (props) => {
  const [errorSection, setErrorSection] = useState({ show: false, text: null });
  const [sharedAttributes, setSharedAttributes] = useState(null);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [saveAttributesLoading, setSaveAttributesLoading] = useState(false);

  React.useEffect(() => {
    const loadData = async () => {
      const attributesData = await populateData();
      setSharedAttributes(attributesData);

      //Populate selected attiributes based on advisor sharedAttribute IDs
      const allAttributes = props.advisor.iCanHelpYouWith.map((a) => a.id).concat(props.advisor.iEnjoyWorkingWith.map((a) => a.id));
      setSelectedAttributes(allAttributes);

      validateSelectedAttributes(allAttributes);
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    let newArray = [];

    if (checked) {
      newArray = [...selectedAttributes, value];
      setSelectedAttributes(newArray);
    } else {
      newArray = selectedAttributes.filter((v) => v !== value);
      setSelectedAttributes(newArray);
    }

    validateSelectedAttributes(newArray);
  };

  // NOTE: We have to pass parameters to this function instead of referencing state variables because
  // we will get stale state values if this code references them in the same render cycle that they get set
  function validateSelectedAttributes(allSelectedAttributes) {
    const countOfSelectedAttributes1 = getCountOfSelectedAttributesBySection(1, allSelectedAttributes);
    const countOfSelectedAttributes2 = getCountOfSelectedAttributesBySection(2, allSelectedAttributes);

    if (countOfSelectedAttributes1 > attributesSectionLimit || countOfSelectedAttributes2 > attributesSectionLimit) {
      showError(`Please select up to ${attributesSectionLimit} details from each section.`);
    } else {
      hideError();
    }
  }

  const onSave = async (event) => {
    event.preventDefault();

    setSaveAttributesLoading(true);

    HttpHelper.put('/api/advisor/sharedattributes', selectedAttributes)
      .then(() => {
        setSaveAttributesLoading(false);
        props.save(props.advisor);
      })
      .catch((error) => {
        showError(error);
        setSaveAttributesLoading(false);
      });

    return false;
  };

  function showError(text) {
    let errorSection = {};

    errorSection.show = true;
    errorSection.text = text.toString();

    setErrorSection(errorSection);
  }

  function hideError() {
    let errorSection = {};

    errorSection.show = false;
    errorSection.text = null;

    setErrorSection(errorSection);
  }

  // NOTE: We have to pass parameters to this function instead of referencing state variables because
  // we will get stale state values if this code references them in the same render cycle that they get set
  function getCountOfSelectedAttributesBySection(sectionType, allSelectedAttributes) {
    if (!sharedAttributes) {
      return 0;
    }

    if (sectionType === 1) {
      const allAttributeIdsInSection = sharedAttributes.iCanHelpYouWith.map((v) => v.id);
      return allSelectedAttributes.filter((v) => allAttributeIdsInSection.includes(v)).length;
    } else {
      const allAttributeIdsInSection = sharedAttributes.iEnjoyWorkingWith.map((v) => v.id);
      return allSelectedAttributes.filter((v) => allAttributeIdsInSection.includes(v)).length;
    }
  }

  function isSaveButtonEnabled() {
    if (saveAttributesLoading) {
      return false;
    }

    // We only allow the user to choose up to x attributes from each section
    const countOfSelectedAttributes1 = getCountOfSelectedAttributesBySection(1, selectedAttributes);
    const countOfSelectedAttributes2 = getCountOfSelectedAttributesBySection(2, selectedAttributes);

    if (countOfSelectedAttributes1 > attributesSectionLimit || countOfSelectedAttributes2 > attributesSectionLimit) {
      return false;
    }

    return true;
  }

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <Form onSubmit={onSave}>
        <ModalHeader toggle={props.toggle}>
          <div>Edit Experience Details</div>
        </ModalHeader>
        <ModalBody>
          {errorSection.show && <Alert color="danger">{errorSection.text}</Alert>}

          <h3>
            I Can Help You With
            <div className="c-gray">
              <div className="mb-2">
                (Choose up to {attributesSectionLimit}
                {getCountOfSelectedAttributesBySection(1, selectedAttributes) > 0 ? (
                  <>
                    <span className={'mx-2'}>&bull;</span>
                    <span className={getCountOfSelectedAttributesBySection(1, selectedAttributes) > attributesSectionLimit ? 'c-red' : ''}>
                      {getCountOfSelectedAttributesBySection(1, selectedAttributes)} Currently Selected
                    </span>
                  </>
                ) : (
                  <></>
                )}
                )
              </div>
            </div>
          </h3>

          {sharedAttributes && (
            <Row>
              {sharedAttributes.iCanHelpYouWith
                .sort((a, b) => (a.title > b.title ? 1 : -1))
                .map((item) => (
                  <Col key={item.id} sm="6" xs="12">
                    <Input
                      type="checkbox"
                      id={item.id}
                      value={item.id}
                      checked={selectedAttributes.includes(item.id)}
                      onChange={handleCheckboxChange}
                    />{' '}
                    <Label for={item.id}>
                      <i className={'fa-light att-icon ' + item.iconTag}></i> {item.title}
                    </Label>
                  </Col>
                ))}
            </Row>
          )}

          <h3 className="mt-2">
            I Enjoy Working With
            <div className="c-gray">
              <span className="mb-2">
                (Choose up to {attributesSectionLimit}
                {getCountOfSelectedAttributesBySection(2, selectedAttributes) > 0 ? (
                  <>
                    <span className="mx-2">&bull;</span>

                    <span className={getCountOfSelectedAttributesBySection(2, selectedAttributes) > attributesSectionLimit ? 'c-red' : ''}>
                      {getCountOfSelectedAttributesBySection(2, selectedAttributes)} Currently Selected
                    </span>
                  </>
                ) : (
                  <></>
                )}
                )
              </span>
            </div>
          </h3>
          {sharedAttributes && (
            <Row>
              {sharedAttributes.iEnjoyWorkingWith
                .sort((a, b) => (a.title > b.title ? 1 : -1))
                .map((item) => (
                  <Col key={item.id} sm="6" xs="12">
                    <Input
                      type="checkbox"
                      id={item.id}
                      value={item.id}
                      checked={selectedAttributes.includes(item.id)}
                      onChange={handleCheckboxChange}
                    />{' '}
                    <Label for={item.id}>
                      <i className={'fa-light att-icon ' + item.iconTag}></i> {item.title}
                    </Label>
                  </Col>
                ))}
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={!isSaveButtonEnabled()}>
            {saveAttributesLoading ? <LoadingDots /> : 'Save Details'}
          </Button>{' '}
        </ModalFooter>
      </Form>
    </Modal>
  );
};
