import { subYears } from 'date-fns';
import { orderBy, sortBy, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import { Link, useParams } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { ApplicationRoutes, SortOptions } from '../../../../constants';
import useNavigationService from '../../../../hooks/UseNavigationService';
import AdvisorsApiService from '../../../../services/api/AdvisorsApiService';
import ReviewsApiService from '../../../../services/api/ReviewsApiService';
import UsersApiService from '../../../../services/api/UsersApiService';
import { ErrorLogService } from '../../../../services/ErrorLogService';
import AuthorizeService from '../../../api-authorization/AuthorizeService';
import { DisclaimerRow } from '../../../DisclaimerRow/DisclaimerRow';
import DateHelper from '../../../Helpers/DateHelper';
import ErrorsHelper from '../../../Helpers/ErrorsHelper';
import StateHelper from '../../../Helpers/StateHelper';
import { FAQs } from '../../../HomeElements/FAQs';
import { useLoadingOverlayContext } from '../../../LoadingAnimations/LoadingOverlay/LoadingOverlayContext';
import LoadingSpinner from '../../../LoadingAnimations/LoadingSpinner/LoadingSpinner';
import { AdvisorEditPersonalPhoto } from '../../Account/Advisor/AdvisorEditPersonalPhoto/AdvisorEditPersonalPhoto';
import { AdvisorEditProfilePhoto } from '../../Account/Advisor/AdvisorEditProfilePhoto/AdvisorEditProfilePhoto';
import './AdvisorDetails.scss';
import { AdvisorEditAttributes } from './AdvisorEditAttributes/AdvisorEditAttributes';
import { AdvisorEditBio } from './AdvisorEditBio/AdvisorEditBio';
import { EditCustomAttributes } from './AdvisorEditCustomAttributes/AdvisorEditCustomAttributes';

interface ReviewsViewInformation {
  currentPage: number;
  sortOption: SortOptions;
  filteredByRating: number | null;
}

const filteredAttributesSessionKey = 'filteredAttributes';
const stateDropdownAbbreviation_NotUS = 'non-us';

const stateDropdownOptions = [
  {
    name: `I don't live in the US`,
    abbreviation: stateDropdownAbbreviation_NotUS
  },
  ...StateHelper.usStatesAndTerritories
];

const reviewsResultsPerPage = 10;

const defaultReviewViewInformation: ReviewsViewInformation = {
  currentPage: 1,
  filteredByRating: null,
  sortOption: SortOptions.Newest
};

const planOptionTitle_TheHour = 'The Hour';
const planOptionTitle_ThePlan = 'The Plan';

const thePlanPrice = 2500;

const planOptions: { title: string; bulletPoints: string[]; price: number; learnMoreText?: string }[] = [
  {
    title: planOptionTitle_TheHour,
    bulletPoints: ['Ask a financial advisor anything about investing in one hour', 'No commissions, products, or sales pitch'],
    price: 0
  },
  {
    title: planOptionTitle_ThePlan,
    bulletPoints: [
      'An 8 week program to achieve a strategic plan for your financial life',
      'Get an 8+ page financial plan',
      '$0 due now, pay after your 1st meeting'
    ],
    price: thePlanPrice,
    learnMoreText: 'Learn more about The Plan'
  }
];

export const AdvisorDetails = () => {
  const componentName = 'AdvisorDetails';

  const { slug } = useParams();

  const { navigateTo_Error } = useNavigationService();
  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlayContext();

  const [advisor, setAdvisor] = useState<Advisor_BasicDetails_ViewModel | null>(null);
  const [consumer, setConsumer] = useState<User_BasicDetails_ViewModel | null>(null);
  const [reviewsAggregateData, setReviewsAggregateData] = useState<Advisor_ReviewsAggregateData_ViewModel | null>(null);
  // const [reviewsData, setReviewsData] = useState<Reviews_Review_ViewModel[] | null>(null);
  const [sortedReviewsData, setSortedReviewsData] = useState<Reviews_Review_ViewModel[]>([]);

  const [redirectTo, setRedirectTo] = useState<boolean>(false);
  const [errorSection, setErrorSection] = useState<{ show: boolean; text: string | null }>({ show: false, text: null });
  const [agreeIsChecked, setAgreeIsChecked] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [state, setState] = useState(localStorage.getItem('userState'));
  const [stateIsInUS, setStateIsInUS] = useState<boolean>(false);
  const [editBioModal, setEditBioModal] = useState<boolean>(false);
  const [editAttributesModal, setEditAttributesModal] = useState<boolean>(false);
  const [editEducationModal, setEditEducationModal] = useState<boolean>(false);
  const [editExperienceModal, setEditExperienceModal] = useState<boolean>(false);
  const [editPhotoModal, setEditPhotoModal] = useState<boolean>(false);
  const [editProfilePicModal, setEditProfilePicModal] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [isLoadingReviews, setIsLoadingReviews] = useState<boolean>(false);
  const [selectedPlanOption, setSelectedPlanOption] = useState<string>(planOptions[0].title);
  const [reviewViewOptions, setReviewViewOptions] = useState<ReviewsViewInformation>(defaultReviewViewInformation);

  const toggleEditBioModal = () => setEditBioModal(!editBioModal);
  const toggleEditPhotoModal = () => setEditPhotoModal(!editPhotoModal);
  const toggleEditProfilePicModal = () => setEditProfilePicModal(!editProfilePicModal);
  const toggleEditAttributesModal = () => setEditAttributesModal(!editAttributesModal);
  const toggleEditEducationModal = () => {
    if (editEducationModal) {
      hardRefresh();
    }

    setEditEducationModal(!editEducationModal);
  };
  const toggleEditExperienceModal = () => {
    if (editExperienceModal) {
      hardRefresh();
    }

    setEditExperienceModal(!editExperienceModal);
  };

  const now = new Date();
  const fiftyYearsAgo = subYears(now, 50);

  useEffect(() => {
    setStateIsInUS(state !== stateDropdownAbbreviation_NotUS);
  }, [state]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const isAuthenticatedResult = await AuthorizeService.isAuthenticated();
        setIsAuthenticated(isAuthenticatedResult);

        const userViewModel = await AuthorizeService.populateActiveUser();
        setConsumer(userViewModel);

        const advisorObj = await AdvisorsApiService.getAdvisorDetailsBySlug(slug ?? '', true, true, false);
        advisorObj.experienceStart = new Date(advisorObj.experienceStart);
        setAdvisor(advisorObj);

        planOptions[0].price = advisorObj.hourlyRate;

        const [reviewsAggregateDataResult, reviewsDataResult] = await Promise.all([
          ReviewsApiService.getAdvisorReviewsAggregateData(advisorObj.id),
          ReviewsApiService.getAdvisorReviewsData(
            advisorObj.id,
            reviewViewOptions.currentPage,
            reviewViewOptions.sortOption,
            reviewViewOptions.filteredByRating ?? 0
          )
        ]);

        setReviewsAggregateData(reviewsAggregateDataResult);
        setSortedReviewsData(reviewsDataResult);
      } catch (error) {
        ErrorLogService.logError(`${componentName} - loadData()`, 'An error occurred while loading page data.', error);
        navigateTo_Error();
      }
    };
    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated && consumer && advisor && consumer.id == advisor.id) {
      setCanEdit(true);
    }
  }, [isAuthenticated, consumer, advisor]);

  // NOTE: This MUST come after any useEffects, or it violates the rule of hooks and React breaks
  if (redirectTo) {
    if (selectedPlanOption === planOptionTitle_TheHour) {
      window.location.href = getRedirectLinkForTheHour();
      return <LoadingSpinner message="Looking up calendar..." />;
    } else {
      window.location.href = ApplicationRoutes.ThePlan_Signup;
    }
  }

  async function updateDisplayedReviews(viewOptions: ReviewsViewInformation, changedProperty: keyof ReviewsViewInformation) {
    try {
      setIsLoadingReviews(true);

      const nextResults = await ReviewsApiService.getAdvisorReviewsData(
        advisor!.id,
        viewOptions.currentPage,
        viewOptions.sortOption,
        viewOptions.filteredByRating ?? 0
      );

      let newReviewsList: Reviews_Review_ViewModel[] = [];

      // Start fresh if the sort or filter options changed
      if (changedProperty === 'sortOption' || changedProperty === 'filteredByRating') {
        newReviewsList = [...nextResults];
      } else {
        newReviewsList = [...sortedReviewsData, ...nextResults];

        // Scroll to the first of the new batch of reviews we just got from the server,
        // if we are appending more reviews to existing ones
        const firstResultOfNewData = nextResults[0];
        if (firstResultOfNewData) {
          setTimeout(() => {
            const elementToScrollTo = document.getElementById(firstResultOfNewData.id);
            if (elementToScrollTo) {
              document.getElementById(firstResultOfNewData.id)?.scrollIntoView();
            }
          }, 500);
        }
      }

      newReviewsList = uniqBy(newReviewsList, (v) => v.id);

      setSortedReviewsData(newReviewsList);
    } catch (error) {
      // TODO
    } finally {
      setIsLoadingReviews(false);
    }
  }

  function closeAllModals() {
    setEditBioModal(false);
    setEditAttributesModal(false);
    setEditEducationModal(false);
    setEditExperienceModal(false);
    setEditPhotoModal(false);
    setEditProfilePicModal(false);
  }

  async function hardRefresh() {
    const advisorObj = await AdvisorsApiService.getAdvisorDetailsBySlug(slug ?? '', true, true, false);
    setAdvisor(advisorObj);

    closeAllModals();
  }

  function isScheduleButtonEnabled() {
    return stateIsInUS && agreeIsChecked;
  }

  function docSeparator(i: number, length: number) {
    if (i == 0 && length == 2) {
      return <span> and </span>;
    }
    if (i < length - 2) {
      return <span>, </span>;
    }
    if (i < length - 1) {
      return <span>, and </span>;
    }

    return <span> </span>;
  }

  const onStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    setState(value);

    if (value !== stateDropdownAbbreviation_NotUS) {
      localStorage.setItem('userState', e.target.value);
    }
  };

  async function onScheduleClick(e: React.FormEvent, state: string) {
    e.preventDefault();
    hideError();

    try {
      showLoadingOverlay();

      await UsersApiService.agreeToPlanTerms(advisor!.id, selectedPlanOption === planOptionTitle_TheHour ? 'TheHour' : 'ThePlan', state);

      setRedirectTo(true);
    } catch (error: any) {
      showError(ErrorsHelper.getErrorMessageFromErrorObject(error.message));
    } finally {
      hideLoadingOverlay();
    }

    return false;
  }

  function showError(text: string): void {
    setErrorSection({ show: true, text });
  }

  function hideError(): void {
    setErrorSection({ show: false, text: null });
  }

  function getRedirectLinkForTheHour(): string {
    // const params = new URLSearchParams(window.location.search);

    if (!consumer || !advisor) {
      return '';
    }

    const outputParams = new URLSearchParams();
    outputParams.set('email', consumer.email);
    outputParams.set('name', consumer.firstName + ' ' + consumer.lastName);

    return advisor.calendarLink + '?' + outputParams.toString();
  }

  function getResultsLink() {
    let url = `/${ApplicationRoutes.Advisors}`;

    const filteredAttributesSessionDataString = sessionStorage.getItem(filteredAttributesSessionKey);
    const filteredAttributesSessionData = filteredAttributesSessionDataString ? JSON.parse(filteredAttributesSessionDataString) : null;

    if (filteredAttributesSessionData && filteredAttributesSessionData.length) {
      url += '?filters=' + filteredAttributesSessionData.join('+');
    }

    return url;
  }

  function getStyleOfReviewBar(barValue: number): any {
    if (!reviewsAggregateData) {
      return {};
    }

    const returnValue: any = {
      width: (reviewsAggregateData.reviewsByValue[barValue] / reviewsAggregateData.totalReviews) * 100 + '%'
    };

    return returnValue;
  }

  function getRemainingReviewResultsToDisplay(): number {
    return !reviewsAggregateData
      ? 0
      : Math.max(0, reviewsAggregateData.totalReviews - reviewViewOptions.currentPage * reviewsResultsPerPage);
  }

  function onSelectedPlanOptionClick(title: string): void {
    setSelectedPlanOption(title);

    // Reset the agree checkbox
    setAgreeIsChecked(false);
  }

  return (
    <Container>
      <Helmet>
        {advisor ? (
          <title>
            {advisor.firstName} {advisor.lastName}
            {advisor.certifications !== null && advisor.certifications !== undefined && advisor.certifications !== ''
              ? ', ' + advisor.certifications
              : ''}{' '}
            - Fiduciary Financial Advisor
          </title>
        ) : (
          <div></div>
        )}

        {!advisor ? <title>Loading... - Nectarine</title> : <div></div>}
      </Helmet>

      <Row>
        <Link to={getResultsLink()}>
          <i className="fa-regular fa-arrow-left back-arrow"></i> Back
        </Link>
      </Row>

      {!advisor ? <LoadingSpinner /> : <></>}

      {advisor ? (
        <Row className="advisor-details">
          <Col xl="4" lg="5">
            <div className="checkout-box position-relative">
              {canEdit ? (
                <div>
                  <Button className="end-0 position-absolute me-3" color="light" onClick={toggleEditProfilePicModal}>
                    <i className="fa-light fa-pen-to-square"></i> Edit Profile Pic
                  </Button>
                  <AdvisorEditProfilePhoto
                    show={editProfilePicModal}
                    toggle={toggleEditProfilePicModal}
                    save={hardRefresh}
                    advisor={advisor}
                  />
                </div>
              ) : (
                <></>
              )}

              <img
                src={advisor.profilePicUrl}
                className="circle-image headshot center"
                alt={advisor.firstName + ' ' + advisor.lastName + ', ' + advisor.certifications}
              />

              <h2 className="center">
                {advisor.firstName} {advisor.lastName}
                {advisor.certifications !== null && advisor.certifications !== undefined && advisor.certifications !== '' ? ', ' : ''}
                {advisor.certifications}
              </h2>
              <h3 className="center">{advisor.title}</h3>

              <div className="planOptions mt-4">
                {planOptions.map((currPlan) => {
                  return (
                    <React.Fragment key={currPlan.title}>
                      <div
                        className={`planOption mt-3 ${selectedPlanOption === currPlan.title ? 'isSelected' : ''}`}
                        onClick={() => {
                          onSelectedPlanOptionClick(currPlan.title);
                        }}
                      >
                        <h2>{currPlan.title}</h2>

                        <div className="radioButton"></div>

                        <ul className="mb-2">
                          {currPlan.bulletPoints.map((currBulletPoint) => {
                            return <li key={currBulletPoint}>{currBulletPoint}</li>;
                          })}
                        </ul>

                        {currPlan.price > 0 ? (
                          <div className="price">${Math.round(currPlan.price).toLocaleString('en-US')}</div>
                        ) : (
                          <Skeleton width={80} height={45} inline={true} />
                        )}
                      </div>

                      {currPlan.learnMoreText ? (
                        <Link to={`/${ApplicationRoutes.ThePlan}`} className="learnMoreLink mt-2">
                          <i className="fa-light fa-circle-question me-2"></i>
                          <div className="text-center c-gray">{currPlan.learnMoreText}</div>
                        </Link>
                      ) : (
                        <> </>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>

              {!isAuthenticated && (
                <div>
                  <Row className="center">
                    <Link to={`/${ApplicationRoutes.Register}?advisor=${advisor.slug}`} className="btn btn-primary btn-lg schedule-btn">
                      Schedule an appointment
                    </Link>
                  </Row>
                </div>
              )}
              {isAuthenticated ? (
                <Form onSubmit={(e) => onScheduleClick(e, state!)}>
                  <Row className="center agree-section">
                    <Label for="state" className="state-label">
                      Which state do you live in?
                      <i id="notUsTooltipIcon" className="ms-2 light-blue fa-regular fa-circle-info"></i>
                      <UncontrolledTooltip target="notUsTooltipIcon">
                        We ask for your state so we can stay in compliance with state and federal regulations. Currently our services are
                        only available to US residents.
                      </UncontrolledTooltip>
                    </Label>
                    <select id="state" name="state" className="state-search" required onChange={onStateChange} value={state ?? undefined}>
                      <option value="">Which state do you live in?</option>
                      {stateDropdownOptions.map((state) => (
                        <option key={state.abbreviation} value={state.abbreviation}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                    <br />

                    <Input
                      id="agreeToTerms"
                      name="agreeToTerms"
                      type="checkbox"
                      onChange={() => {
                        setAgreeIsChecked((v) => !v);
                      }}
                      checked={agreeIsChecked}
                    />

                    {selectedPlanOption === planOptionTitle_TheHour ? (
                      <Label for="agreeToTerms">
                        I agree to the
                        {advisor.nectarineHourComplianceDocuments.map((v, i) => (
                          <span key={v.displayName}>
                            {' '}
                            <a href={v.url} target="_blank" rel="noopener noreferrer">
                              {v.displayName}
                            </a>
                            {docSeparator(i, advisor.nectarineHourComplianceDocuments.length)}
                          </span>
                        ))}
                        of Nectarine
                        {advisor.advisorHourComplianceDocuments.length > 0 ? (
                          <span>
                            {' '}
                            and the
                            {advisor.advisorHourComplianceDocuments.map((currDocument, i) => (
                              <span key={currDocument.id}>
                                {' '}
                                <a href={currDocument.url} target="_blank" rel="noopener noreferrer">
                                  {currDocument.displayName}
                                </a>
                                {docSeparator(i, advisor.advisorHourComplianceDocuments.length)}
                              </span>
                            ))}
                            of {advisor.firstName} {advisor.lastName}
                          </span>
                        ) : (
                          <></>
                        )}
                      </Label>
                    ) : (
                      <></>
                    )}

                    {selectedPlanOption === planOptionTitle_ThePlan ? (
                      <Label for="agreeToTerms">
                        I agree to Nectarine's
                        {advisor.nectarinePlanComplianceDocuments.map((currDocument, i) => (
                          <span key={i}>
                            {' '}
                            <a href={currDocument.url} target="_blank" rel="noopener noreferrer">
                              {currDocument.displayName}
                            </a>
                            {docSeparator(i, advisor.nectarinePlanComplianceDocuments.length)}
                          </span>
                        ))}
                      </Label>
                    ) : (
                      <></>
                    )}
                  </Row>

                  {!stateIsInUS ? (
                    <Alert color="danger" className="mt-4">
                      Currently our services are only available to US residents. &nbsp;
                      <Link className="mt-4" to={`/${ApplicationRoutes.NonUS}`}>
                        Learn more.
                      </Link>
                    </Alert>
                  ) : (
                    <></>
                  )}

                  {errorSection.show && (
                    <Alert color="danger" className="mt-4">
                      {errorSection.text}
                    </Alert>
                  )}

                  <div className="center">
                    <Button disabled={!isScheduleButtonEnabled()} size="md" className="schedule-btn">
                      {selectedPlanOption === planOptionTitle_TheHour ? (
                        <>Book now - ${Math.round(advisor!.hourlyRate).toLocaleString('en-US')}</>
                      ) : (
                        <>Sign up - ${Math.round(thePlanPrice).toLocaleString('en-US')} ($0 due upfront)</>
                      )}
                    </Button>
                  </div>
                </Form>
              ) : (
                <></>
              )}

              {advisor ? (
                <Row className="center">
                  {new Date(advisor.nextAvailability!) > now && (
                    <div className="next-available">
                      <i className="fa-regular fa-clock att-icon"></i> Next Available:{' '}
                      <strong>{DateHelper.casualDateFormat(advisor.nextAvailability!)}</strong>
                    </div>
                  )}
                </Row>
              ) : (
                <></>
              )}
            </div>
          </Col>

          <Col xl="8" lg="7">
            <h1>About {advisor.firstName}</h1>
            <Row className="details-highlights">
              {advisor.experienceStart > fiftyYearsAgo && advisor.experienceStart < now && (
                <Col lg="12" xl="4" className="center">
                  <i className="fa-regular fa-lightbulb att-icon"></i> <strong>{advisor.yearsExperience}</strong> year
                  {advisor.yearsExperience == 1 ? '' : 's'} of experience
                </Col>
              )}
              {advisor.eventCount > 0 && (
                <Col lg="12" xl="4" className="center">
                  <i className="fa-regular fa-calendar att-icon"></i> <strong>{advisor.eventCount}</strong> meeting
                  {advisor.eventCount == 1 ? '' : 's'} completed
                </Col>
              )}
              {advisor.showReviews && advisor.reviewCount > 0 && (
                <Col lg="12" xl="4" className="center">
                  <i className="fa-solid fa-star att-icon"></i> <strong>{advisor.reviewAverage.toFixed(1)}</strong> average review
                </Col>
              )}
            </Row>
            {canEdit && (
              <div>
                <Button className="float-end" color="light" onClick={toggleEditBioModal}>
                  <i className="fa-light fa-pen-to-square"></i> Edit Bio
                </Button>
                <AdvisorEditBio show={editBioModal} toggle={toggleEditBioModal} save={hardRefresh} advisor={advisor} />
              </div>
            )}
            <p>{advisor.longBio}</p>
            {canEdit && (
              <div>
                <Button className="float-end" color="light" onClick={toggleEditAttributesModal}>
                  <i className="fa-light fa-pen-to-square"></i> Edit Details
                </Button>
                <AdvisorEditAttributes show={editAttributesModal} toggle={toggleEditAttributesModal} save={hardRefresh} advisor={advisor} />
              </div>
            )}

            <h2>I Can Help You With</h2>
            <Row>
              {sortBy(advisor.iCanHelpYouWith, (v) => v.title).map((item) => (
                <Col xs="12" xl="6" className="mb-4" key={item.id}>
                  <i className={'fa-light fa-xl att-icon att-details ' + item.iconTag}></i>
                  <div className="att-caption">{item.title}</div>
                </Col>
              ))}
            </Row>
            <h2>I Enjoy Working With</h2>
            <Row>
              {sortBy(advisor.iEnjoyWorkingWith, (v) => v.title).map((item) => (
                <Col xs="12" xl="6" className="mb-4" key={item.id}>
                  <i className={'fa-light fa-xl att-icon att-details ' + item.iconTag}></i>
                  <div className="att-caption">{item.title}</div>
                </Col>
              ))}
            </Row>
            {canEdit && (
              <Button className="float-end" color="light" onClick={toggleEditBioModal}>
                <i className="fa-light fa-pen-to-square"></i> Edit Personal Stuff
              </Button>
            )}
            <h2>Personal Stuff</h2>
            <p>{advisor.personalBio}</p>
            {canEdit && (
              <div>
                <Button className="float-end" color="light" onClick={toggleEditPhotoModal}>
                  <i className="fa-light fa-pen-to-square"></i> Edit Photo
                </Button>
                <AdvisorEditPersonalPhoto show={editPhotoModal} toggle={toggleEditPhotoModal} save={hardRefresh} advisor={advisor} />
              </div>
            )}

            {advisor.photos.length > 0 ? (
              <Row>
                {advisor.photos.map((item) => (
                  <Col xs="12" className="mb-4" key={item.id}>
                    <img src={item.url} className="personal-photo" />
                  </Col>
                ))}
              </Row>
            ) : (
              <></>
            )}

            {canEdit ? (
              <div>
                <Button className="float-end" color="light" onClick={toggleEditEducationModal}>
                  <i className="fa-light fa-pen-to-square"></i> Edit Education
                </Button>
                <EditCustomAttributes
                  show={editEducationModal}
                  toggle={toggleEditEducationModal}
                  save={toggleEditEducationModal}
                  categoryID={3}
                  title="Education &amp; Designations"
                  attributes={advisor.education}
                />
              </div>
            ) : (
              <></>
            )}

            <h2>Education &amp; Designations</h2>
            <Row>
              {sortBy(advisor.education, (v) => v.title).map((item) => (
                <Col xs="12" xl="6" className="mb-4" key={item.id}>
                  <i className={'fa-light fa-xl att-icon att-details ' + item.iconTag}></i>
                  <div className="att-caption">
                    <div className="att-title">{item.title}</div>
                    <div className="att-subtitle">{item.subtitle}</div>
                  </div>
                </Col>
              ))}
            </Row>
            {canEdit ? (
              <div>
                <Button className="float-end" color="light" onClick={toggleEditExperienceModal}>
                  <i className="fa-light fa-pen-to-square"></i> Edit Experience
                </Button>
                <EditCustomAttributes
                  show={editExperienceModal}
                  toggle={toggleEditExperienceModal}
                  save={toggleEditExperienceModal}
                  categoryID={4}
                  title="Experience"
                  attributes={advisor.experience}
                />
              </div>
            ) : (
              <></>
            )}

            <h2>Experience</h2>
            <Row>
              {orderBy(advisor.experience, [(v) => new Date(v.endDate)], ['desc']).map((item) => (
                <Col xs="12" xl="6" className="mb-4" key={item.id}>
                  <i className={'fa-light fa-xl att-icon att-details ' + item.iconTag}></i>
                  <div className="att-caption">
                    <div className="att-title">{item.title}</div>
                    <div className="att-subtitle">{item.subtitle}</div>
                    <div className="att-subtitle">{DateHelper.printYearRange(item.startDate, item.endDate)}</div>
                  </div>
                </Col>
              ))}
            </Row>

            {advisor.showReviews && reviewsAggregateData ? (
              <>
                <h2>Reviews</h2>

                <div className="reviewsSummary">
                  <div className="d-flex flex-nowrap align-items-center">
                    {[...Array(5).keys()].map((currRating) => {
                      return Math.floor(reviewsAggregateData.overallRating) > currRating ? (
                        <i key={currRating} className={`me-1 fa-solid fa-star greenStar`}></i>
                      ) : reviewsAggregateData.overallRating < currRating ? (
                        <i key={currRating} className={`me-1 fa-solid fa-star grayStar`}></i>
                      ) : (
                        // Partial star
                        <div className="partialStarArea">
                          <i key={currRating} className={`me-1 fa-solid fa-star grayStar`}></i>

                          <i
                            key={currRating}
                            className={`me-1 fa-solid fa-star greenStar`}
                            style={{ width: (reviewsAggregateData.overallRating - currRating) * 100 + '%' }}
                          ></i>
                        </div>
                      );
                    })}
                    <span className="bold ms-3">{reviewsAggregateData.overallRating.toFixed(1)}</span> &nbsp;overall rating (
                    <span className="bold">{reviewsAggregateData.totalReviews}</span> &nbsp;review
                    {reviewsAggregateData.totalReviews === 1 ? '' : 's'})
                  </div>

                  <div className="ratingBarsArea mt-4">
                    {[...Array(5).keys()].reverse().map((currRating) => {
                      return (
                        <div
                          key={currRating}
                          className={`ratingBar pointer ${reviewViewOptions.filteredByRating === currRating + 1 ? 'isSelected' : ''}`}
                          onClick={() => {
                            const newVal: ReviewsViewInformation = {
                              ...reviewViewOptions,
                              filteredByRating: reviewViewOptions.filteredByRating === currRating + 1 ? null : currRating + 1
                            };

                            setReviewViewOptions(newVal);
                            updateDisplayedReviews(newVal, 'filteredByRating');
                          }}
                          title={`${reviewsAggregateData.reviewsByValue[currRating + 1]}`}
                        >
                          <span className="ratingLabel">{currRating + 1}</span>
                          <span
                            className={`filledInArea ${reviewViewOptions.filteredByRating === currRating + 1 ? 'isSelected' : ''}`}
                            style={{ ...getStyleOfReviewBar(currRating + 1) }}
                          ></span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-5">
                    <Label for="reviewsSort" className="bold">
                      Sort
                    </Label>
                  </div>

                  <select
                    id="reviewsSort"
                    name="reviewsSort"
                    onChange={(e) => {
                      const newVal: ReviewsViewInformation = {
                        ...reviewViewOptions,
                        sortOption: parseInt(e.target.value),
                        currentPage: 1
                      };

                      setReviewViewOptions(newVal);
                      updateDisplayedReviews(newVal, 'sortOption');
                    }}
                    value={reviewViewOptions.sortOption}
                  >
                    <option value={SortOptions.Newest}>Newest First</option>
                    <option value={SortOptions.Oldest}>Oldest First</option>
                    <option value={SortOptions.Highest}>Highest First</option>
                    <option value={SortOptions.Lowest}>Lowest First</option>
                  </select>

                  {!sortedReviewsData.length ? (
                    <div className="my-3 text-center">
                      {reviewsAggregateData.totalReviews > 0 ? (
                        <Label>
                          No reviews were found that matched your filters.
                          <br />
                          Click{' '}
                          <span
                            className="underline pointer"
                            onClick={() => {
                              setReviewViewOptions(defaultReviewViewInformation);
                              updateDisplayedReviews(defaultReviewViewInformation, 'sortOption');
                            }}
                          >
                            here
                          </span>
                          &nbsp;to reset them.
                        </Label>
                      ) : (
                        <Label>No reviews were found.</Label>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="mt-5"></div>

                  {sortedReviewsData.map((currReview) => (
                    <div key={currReview.id} id={currReview.id} className="details-review">
                      {[...Array(5).keys()].map((v) => {
                        return (
                          <i
                            key={v}
                            className={`me-1 fa-solid fa-star ${v < Math.floor(currReview.rating) ? 'greenStar' : 'grayStar'}`}
                          ></i>
                        );
                      })}

                      <h3>{currReview.title}</h3>
                      <p>{currReview.body}</p>

                      <div className="review-footer">
                        <div className="reviewer-name">{currReview.displayName}</div>
                        <div className="review-date">{DateHelper.printMonthYearLong(currReview.modifiedAt)}</div>
                      </div>
                    </div>
                  ))}

                  {isLoadingReviews ? <LoadingSpinner message="Loading reviews..." /> : <></>}

                  {getRemainingReviewResultsToDisplay() ? (
                    <div className="text-center mb-5">
                      <Button
                        color="light"
                        onClick={() => {
                          const newVal: ReviewsViewInformation = {
                            ...reviewViewOptions,
                            currentPage: reviewViewOptions.currentPage + 1
                          };

                          setReviewViewOptions(newVal);
                          updateDisplayedReviews(newVal, 'currentPage');
                        }}
                      >
                        Show more ({getRemainingReviewResultsToDisplay()})
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}

            {!advisor.showReviews && advisor.reviewCount > 0 ? (
              <div>
                <h2>Reviews</h2>
                <p>
                  {advisor.firstName} has received {advisor.reviewCount} review{advisor.reviewCount > 1 && 's'} but{' '}
                  {advisor.reviewCount > 1 ? 'they are' : 'it is'} hidden due to state regulations not allowing the posting of testimonials.
                </p>
              </div>
            ) : (
              <></>
            )}

            <h2>Frequently Asked Questions</h2>
            <FAQs />

            <DisclaimerRow />
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </Container>
  );
};
