// @ts-check

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Nav, NavLink, Row } from 'reactstrap';
import { ApplicationRoutes } from '../../../../constants';
import iconImage from './../../../../img/nectarine-icon.png';
import { LoginMenu } from './../../../api-authorization/LoginMenu';
import './Footer.scss';

export class Footer extends Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <footer>
        <Container>
          <Row>
            <Col className="center">
              <NavLink tag={Link} to={ApplicationRoutes.Root}>
                <img src={iconImage} alt="Nectarine Icon" className="icon center" />
              </NavLink>
              &copy;{new Date().getFullYear()} Nectarine, Inc.
            </Col>
            <Col>
              <Nav vertical>
                <h3>Resources</h3>
                <NavLink href="/#home">Get Started</NavLink>
                <NavLink href="/#pricing">Pricing</NavLink>
                <NavLink href="/#experts">Our Experts</NavLink>
                <LoginMenu />
              </Nav>
            </Col>
            <Col>
              <Nav vertical>
                <h3>Company</h3>
                <NavLink tag={Link} to={`/${ApplicationRoutes.Recruit}`}>
                  Advisor Jobs
                </NavLink>
                <NavLink tag={Link} to={`/${ApplicationRoutes.Affiliates}`}>
                  Affiliates
                </NavLink>
                <NavLink tag={Link} to={`/${ApplicationRoutes.About}`}>
                  About Nectarine
                </NavLink>
                <NavLink tag={Link} to={`/${ApplicationRoutes.Contact}`}>
                  Contact Us
                </NavLink>
              </Nav>
            </Col>
            <Col>
              <Nav vertical>
                <h3>Disclosures</h3>
                <NavLink tag={Link} to={`/${ApplicationRoutes.Terms}`}>
                  Terms of Use
                </NavLink>
                <NavLink
                  href="https://s3.amazonaws.com/files.hellonectarine.com/SC8aOavKzk6B2I4Mzd8yvw-nectarine-privacy-policy.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </NavLink>
                <NavLink
                  href="https://s3.amazonaws.com/files.hellonectarine.com/7jXr8nRu0Ot7MGI28ldg-nectarine-adv-part-2a.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Nectarine ADV 2A
                </NavLink>
                <NavLink
                  href="https://s3.amazonaws.com/files.hellonectarine.com/MsArmVi5IkGYOOVyN8jYzQ-nectarine-form-crs.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Nectarine CRS
                </NavLink>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}
