//@ts-check

import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ApplicationRoutes } from '../../../../constants';
import logoImage from '../../../../img/nectarine-logo.png';
import AdvisorsApiService from '../../../../services/api/AdvisorsApiService';
import AuthorizeService from '../../../api-authorization/AuthorizeService';
import HttpHelper from '../../../Helpers/HttpHelper';
import LoadingDots from './../../../LoadingAnimations/LoadingDots/LoadingDots';
import LoadingSpinner from './../../../LoadingAnimations/LoadingSpinner/LoadingSpinner';

export const AccountRegister = () => {
  let navigate = useNavigate();

  const [user, setUser] = useState({ email: '', password: '', confirmPassword: '' });
  const [advisor, setAdvisor] = useState(null);
  const [advisorLoading, setAdvisorLoading] = useState(true);
  const [errorSection, setErrorSection] = useState({ show: false, text: null });
  const [isAuthenticated, setIsAuthenticated] = useState({ loading: true, authenticated: false });
  const [isRegistered, setIsRegistered] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [affiliateSignUpMode, setAffiliateSignUpMode] = useState(false);
  // const [isAffiliateLoading, setIsAffiliateLoading] = useState(true);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const advisorSlug = queryParams.get('advisor');

  useEffect(() => {
    const loadData = async () => {
      const isAffiliateMode = queryParams.get('affiliate') === 'true';

      setAffiliateSignUpMode(isAffiliateMode);

      if (advisorSlug) {
        const advisor = await AdvisorsApiService.getAdvisorDetailsBySlug(advisorSlug, false, false, false);
        setAdvisor(advisor);
      } else {
        setAdvisor(null);
      }

      setAdvisorLoading(false);

      const isAuthenticated = await AuthorizeService.isAuthenticated();
      await onAuthentiated(isAuthenticated, isAffiliateMode);
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onAuthentiated(isAuthenticated, isAffiliateMode) {
    //If we're authenticated and it's affiliate mode, post to the server that we should be an affiliate
    if (isAuthenticated && isAffiliateMode) {
      const addedRole = await addAffiliateRole();

      if (addedRole) {
        redirectBackHereToRefreshRoles();
      }
    }

    setIsAuthenticated({ loading: false, authenticated: isAuthenticated });
  }

  function hideError() {
    setErrorSection({ show: false });
  }

  function showError(text) {
    setErrorSection({ show: true, text: text });
  }

  function onFormChange(e) {
    hideError();
    setUser((a) => ({
      ...a,
      [e.target.id]: e.target.value
    }));
  }

  async function addAffiliateRole() {
    try {
      const response = await HttpHelper.put('/api/home/becomeaffiliate/');

      if (!response.ok || response.status === 204) {
        const text = await response.text();
        throw new Error(text || 'No changes were made');
      }
      return true;
    } catch (error) {
      showError(error.toString());
      return false;
    }
  }

  async function onRegisterClick(e) {
    e.preventDefault();

    setIsSaving(true);

    user.advisorSlug = advisorSlug;
    user.isAffiliate = affiliateSignUpMode;

    HttpHelper.post('/api/home/register/', user)
      .then(() => {
        setIsRegistered(true);

        redirectBackHereToRefreshRoles();

        setIsSaving(false);
      })
      .catch((error) => {
        showError(error.toString());
        setIsSaving(false);
      });

    return false;
  }

  function redirectBackHereToRefreshRoles() {
    //Redirect back to login page, then back to this page so the client roles are refreshed
    const returnUrl = encodeURIComponent(window.location.href);
    navigate(`/${ApplicationRoutes.AuthenticationRoutes.Login}?returnUrl=${returnUrl}`);
  }

  return (
    <Container>
      <Helmet>
        <title>Register a New Account - Nectarine</title>
      </Helmet>
      <Row>
        <Col lg="3" md="0" />

        {(isAuthenticated.loading || advisorLoading) && <LoadingSpinner />}

        {!isAuthenticated.loading && isAuthenticated.authenticated && advisor && (
          <Col lg="6" md="12" className="align-items-center justify-content-center pt-5">
            <div className="line-box register-box">
              <Link to={ApplicationRoutes.Root}>
                <img src={logoImage} alt="Nectarine" className="center" />
              </Link>
              <h2>Welcome to Nectarine!</h2>
              <p>
                To finish scheduling your meeting with <strong>{advisor.fullName}</strong> click continue below to visit the advisor's page
                and then click the schedule meeting button.
              </p>
              <img
                src={advisor.profilePicUrl}
                className="circle-image headshot center"
                alt={advisor.firstName + ' ' + advisor.lastName + ', ' + advisor.certifications}
              />
              <p>
                <Link to={`/${ApplicationRoutes.Advisor}/${advisor.slug}`} className="btn btn-primary btn-lg center">
                  Continue
                </Link>
              </p>
            </div>
          </Col>
        )}
        {!isAuthenticated.loading && !advisorLoading && isAuthenticated.authenticated && !advisor && !affiliateSignUpMode && (
          <Col lg="6" md="12" className="align-items-center justify-content-center pt-5">
            <div className="line-box register-box">
              <Link to={ApplicationRoutes.Root}>
                <img src={logoImage} alt="Nectarine" className="center" />
              </Link>
              <h2>Hey! It looks like you're already logged in!</h2>
              <p>
                You can visit <Link to={`/${ApplicationRoutes.ClientAccountRoutes.Root}`}>your account</Link>, our
                <Link to={ApplicationRoutes.Root}> home page</Link> or
                <Link to={`/${ApplicationRoutes.Logout}`} state={{ local: true }}>
                  logout
                </Link>
                .
              </p>
            </div>
          </Col>
        )}
        {!isAuthenticated.loading && !advisorLoading && isAuthenticated.authenticated && !advisor && affiliateSignUpMode && (
          <Col lg="6" md="12" className="align-items-center justify-content-center pt-5">
            <div className="line-box register-box">
              <Link to={ApplicationRoutes.Root}>
                <img src={logoImage} alt="Nectarine" className="center" />
              </Link>
              <h2>You're all signed up!</h2>
              <p>
                Visit your <Link to={`/${ApplicationRoutes.ClientAccountRoutes.Share_Full}`}>account</Link> to and click
                <Link to={`/${ApplicationRoutes.Logout}`}> Share Nectarine</Link> to get started.
              </p>
            </div>
          </Col>
        )}
        {!isAuthenticated.loading && !advisorLoading && !isAuthenticated.authenticated && !isRegistered && (
          <Col lg="6" md="12" className="align-items-center justify-content-center">
            <div className="line-box register-box">
              <Link to={ApplicationRoutes.Root}>
                <img src={logoImage} alt="Nectarine" className="center" />
              </Link>
              {advisor && (
                <p>
                  To book a meeting with {advisor.firstName}, <Link to={`/${ApplicationRoutes.AuthenticationRoutes.Login}`}>login</Link> or
                  create your account below.
                </p>
              )}
              <h2>Create an Account</h2>
              <Form onSubmit={onRegisterClick}>
                <FormGroup>
                  <Label for="firstName">Name</Label>
                  <Row>
                    <Col sm="6">
                      <Input
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        type="text"
                        required
                        value={user.firstName}
                        onChange={(e) => onFormChange(e)}
                      />
                    </Col>
                    <Col sm="6">
                      <Input
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        type="text"
                        required
                        value={user.lastName}
                        onChange={(e) => onFormChange(e)}
                      />
                    </Col>
                  </Row>
                  {affiliateSignUpMode && (
                    <Row>
                      <Alert color="info" className="mt-2">
                        <strong>Important!</strong> Please use your real legal name for compliance and payment purposes.
                      </Alert>
                    </Row>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    id="email"
                    name="email"
                    placeholder="name@example.com"
                    type="email"
                    required
                    value={user.email}
                    onChange={(e) => onFormChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Choose a password</Label>
                  <Input id="password" name="password" type="password" required value={user.password} onChange={(e) => onFormChange(e)} />
                </FormGroup>
                <FormGroup>
                  <Label for="confirmPassword">Re-enter password</Label>
                  <Input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    required
                    value={user.confirmPassword}
                    onChange={(e) => onFormChange(e)}
                  />
                </FormGroup>
                {errorSection.show && <Alert color="danger">{errorSection.text}</Alert>}
                <Button color="primary" size="lg" className="float-end mt-3" disabled={isSaving}>
                  {isSaving ? <LoadingDots /> : 'Create Account'}
                </Button>
                <div className="clearfix"></div>
              </Form>
            </div>
            <div className="text-center register-footer">
              <Link
                tag={Link}
                to={`/${ApplicationRoutes.AuthenticationRoutes.Login}?returnUrl=${encodeURIComponent(window.location.href)}`}
              >
                Login
              </Link>{' '}
              &bull;{' '}
              <Link tag={Link} to={`/${ApplicationRoutes.Contact}`}>
                Contact
              </Link>{' '}
              &bull;{' '}
              <Link tag={Link} to={`/${ApplicationRoutes.Terms}`}>
                Terms
              </Link>{' '}
              &bull;{' '}
              <Link tag={Link} to={`/${ApplicationRoutes.Privacy}`}>
                Privacy
              </Link>
            </div>
          </Col>
        )}
        {!isAuthenticated.loading && isRegistered && (
          <Col lg="6" md="12" className="align-items-center justify-content-center">
            <div className="line-box register-box">
              <Link to={ApplicationRoutes.Root}>
                <img src={logoImage} alt="Nectarine" className="center" />
              </Link>
              <h2>Welcome!</h2>
              <p>
                Your account is created and ready to go! Now you can visit{' '}
                <Link to={`/${ApplicationRoutes.ClientAccountRoutes.Root}`}>your account</Link>.
              </p>
            </div>
            <div className="text-center register-footer">
              <Link
                tag={Link}
                to={`/${ApplicationRoutes.AuthenticationRoutes.Login}?returnUrl=${encodeURIComponent(window.location.href)}`}
              >
                Login
              </Link>{' '}
              &bull;{' '}
              <Link tag={Link} to={`/${ApplicationRoutes.Contact}`}>
                Contact
              </Link>{' '}
              &bull;{' '}
              <Link tag={Link} to={`/${ApplicationRoutes.Terms}`}>
                Terms
              </Link>{' '}
              &bull;{' '}
              <Link tag={Link} to={`/${ApplicationRoutes.Privacy}`}>
                Privacy
              </Link>
            </div>
          </Col>
        )}

        <Col lg="3" md="0" />
      </Row>
    </Container>
  );
};
