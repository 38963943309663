import HttpHelper from '../../components/Helpers/HttpHelper';
import { ErrorLogService } from '../ErrorLogService';
import ApiServiceBase from './ApiServiceBase';

export default class AdvisorsApiService extends ApiServiceBase {
  private static className = 'AdvisorsApiService';

  public static async getAllAdvisorDetails(queryStringParams?: string): Promise<Advisor_BasicDetails_ViewModel[]> {
    const url = `api/AdvisorResults/get-advisors-details${queryStringParams ?? ''}`;

    try {
      const result = await this.makeApiCall<Advisor_BasicDetails_ViewModel[]>(() => HttpHelper.get(url));

      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAllAdvisorDetails()`,
        `An error occurred while loading advisors details: ${url}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorDetailsBySlug(
    slug: string,
    showAttributes: boolean,
    showDetails: boolean,
    showReviewDetails: boolean
  ): Promise<Advisor_BasicDetails_ViewModel> {
    try {
      const result = await this.makeApiCall<Advisor_BasicDetails_ViewModel>(() =>
        HttpHelper.get(`api/users/slug/${showAttributes}/${showDetails}/${showReviewDetails}/${slug}`)
      );
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAdvisorDetailsBySlug()`,
        `An error occurred while loading advisor details with slug: ${slug}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorsToReview(): Promise<User_BasicDetails_ViewModel[]> {
    try {
      const result = await this.makeApiCall<User_BasicDetails_ViewModel[]>(() => HttpHelper.get(`api/reviews/advisors-to-review`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getAdvisorsToReview()`, `An error occurred while loading advisors to review.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorReviews(): Promise<Reviews_Review_ViewModel[]> {
    try {
      const result = await this.makeApiCall<Reviews_Review_ViewModel[]>(() => HttpHelper.get(`api/advisor/reviews`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAdvisorReviews()`,
        `An error occurred while loading reviews for the advisor.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorClientInfo(clientId: string): Promise<Advisor_ClientDetails_ViewModel> {
    try {
      const result = await this.makeApiCall<Advisor_ClientDetails_ViewModel>(() => HttpHelper.get(`api/advisor/client/${clientId}`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAdvisorClientInfo()`,
        `An error occurred while loading advisor client data for client ID: ${clientId}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async updateAdvisor(editModel: Advisor_EditAdvisor_EditModel): Promise<void> {
    try {
      await this.makeApiCall<void>(() => HttpHelper.put(`api/advisor/update-advisor`, editModel));
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - updateAdvisor()`, `An error occurred while updating advisor information.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async updateAdvisorBioOrHourlyRate(editModel: Advisor_EditAdvisor_EditModel): Promise<void> {
    try {
      await this.makeApiCall<void>(() => HttpHelper.put(`api/advisor/update-advisor-2`, editModel));
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - updateAdvisorBioOrHourlyRate()`,
        `An error occurred while updating the advisor bio or hourly rate.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }
}
