import HttpHelper from '../../components/Helpers/HttpHelper';
import { ErrorLogService } from '../ErrorLogService';
import ApiServiceBase from './ApiServiceBase';

const apiRouteBase = 'api/home';

export class DashboardApiService extends ApiServiceBase {
  private static className = 'DashboardApiService';

  public static async getDashboardData(): Promise<Home_DashboardPage_ViewModel> {
    try {
      const result = await this.makeApiCall<Home_DashboardPage_ViewModel>(() => HttpHelper.get(`${apiRouteBase}/dashboard`));

      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getDashboardData()`, 'An error occurred while loading dashboard data.', error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAllAdvisorAttributes(): Promise<Advisor_Attribute_ViewModel[]> {
    try {
      const result = await this.makeApiCall<Advisor_Attribute_ViewModel[]>(() => HttpHelper.get(`${apiRouteBase}/seodata`));

      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getAdvisorAttributes()`, 'An error occurred while loading advisor attributes.', error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }
}
