import HttpHelper from '../../components/Helpers/HttpHelper';
import { ErrorLogService } from '../ErrorLogService';
import ApiServiceBase from './ApiServiceBase';

const adminApiRouteBase = 'api/admin';

export default class AdminApiService extends ApiServiceBase {
  private static className = 'AdminApiService';

  public static async getAllReviews(): Promise<Reviews_Review_ViewModel[]> {
    try {
      const result = await this.makeApiCall<Reviews_Review_ViewModel[]>(() => HttpHelper.get(`${adminApiRouteBase}/reviews`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getAllReviews()`, 'An error occurred while loading reviews for admins.', error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async deleteReview(reviewId: string): Promise<void> {
    try {
      await this.makeApiCall(() => HttpHelper.delete(`${adminApiRouteBase}/reviews/${reviewId}`));
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - deleteReview()`,
        `An error occurred while deleting the review with ID: ${reviewId}`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAllUsers(): Promise<User_BasicDetails_ViewModel[]> {
    try {
      const result = await this.makeApiCall<User_BasicDetails_ViewModel[]>(() => HttpHelper.get(`api/users`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getAllUsers()`, `An error occurred while getting all users.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getUserById(userId: string): Promise<Admin_UserDetails_ViewModel> {
    try {
      const result = await this.makeApiCall<Admin_UserDetails_ViewModel>(() => HttpHelper.get(`api/users/${userId}`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getUserById()`, `An error occurred while getting info for user: ${userId}.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getUsersInRole(role: string): Promise<User_BasicDetails_ViewModel[]> {
    try {
      const result = await this.makeApiCall<User_BasicDetails_ViewModel[]>(() => HttpHelper.get(`api/users/roles/${role}`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getUsersInRole()`, `An error occurred while getting users in role: ${role}`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getRolesForUser(userId: string): Promise<string[]> {
    try {
      const result = await this.makeApiCall<string[]>(() => HttpHelper.get(`api/users/${userId}/roles`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getRolesForUser()`,
        `An error occurred while getting roles for user: ${userId}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAllUserRoles(): Promise<Admin_UserRole_ViewModel[]> {
    try {
      const result = await this.makeApiCall<Admin_UserRole_ViewModel[]>(() => HttpHelper.get(`api/admin/roles`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getAllUserRoles()`, `An error occurred while getting all user roles.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async updateUser(editModel: Admin_EditUser_EditModel): Promise<void> {
    try {
      await this.makeApiCall(() => HttpHelper.put(`api/users/${editModel.id}`, editModel));
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - updateUser()`, `An error occurred while updating the user: ${editModel.id}.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async addUserRole(userId: string, role: string): Promise<void> {
    try {
      await this.makeApiCall(() => HttpHelper.post(`api/users/${userId}/roles`, role));
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - addUserRole()`,
        `An error occurred while adding a role (${role}) to the user: ${userId}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async deleteUserRole(userId: string, role: string): Promise<void> {
    try {
      await this.makeApiCall(() => HttpHelper.delete(`api/users/${userId}/roles`, role));
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - deleteUserRole()`,
        `An error occurred while deleting a role (${role}) from the user: ${userId}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAllEvents(): Promise<Admin_Event_ViewModel[]> {
    try {
      const result = await this.makeApiCall<Admin_Event_ViewModel[]>(() => HttpHelper.get(`api/admin/events`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getAllEvents()`, `An error occurred while getting all events.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getEventById(eventId: string): Promise<Admin_Event_ViewModel> {
    try {
      const result = await this.makeApiCall<Admin_Event_ViewModel>(() => HttpHelper.get(`api/admin/events/${eventId}`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getEventById()`, `An error occurred while getting event for ID: ${eventId}.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async updateEvent(editModel: Admin_Event_EditModel): Promise<void> {
    try {
      const result = await this.makeApiCall(() => HttpHelper.put(`api/admin/events/${editModel.id}`, editModel));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getEventById()`,
        `An error occurred while updating event for ID: ${editModel.id}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async deleteEventById(eventId: string): Promise<void> {
    try {
      await this.makeApiCall(() => HttpHelper.delete(`api/admin/events/${eventId}`));
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - deleteEventById()`,
        `An error occurred while deleting event for ID: ${eventId}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getRecentUserAgreements(): Promise<User_UserAgreement_ViewModel[]> {
    try {
      const result = await this.makeApiCall(() => HttpHelper.get<User_UserAgreement_ViewModel[]>(`api/useragreements/recent`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getRecentUserAgreements()`,
        `An error occurred while getting recent user agreements.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }
}
