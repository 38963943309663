//@ts-check

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import heroImage from './../../../img/investing-experts.png';

export const Contact = () => {
  const [messageSentSection, setMessageSentSection] = useState({ show: false });

  function ShowMessage(text) {
    let errorSection = {};
    errorSection.show = true;
    errorSection.text = text.toString();
    setMessageSentSection(errorSection);
  }

  const onSave = async (event) => {
    event.preventDefault();

    // extract form data
    const formdata = new FormData(event.target);

    // console.log('onSubmit formData', formdata);

    // convert FormData to json object
    // SOURCE: https://stackoverflow.com/a/46774073
    const formItem = {};
    formdata.forEach(function (value, prop) {
      formItem[prop] = value;
    });

    // console.log('onSave json', formItem);

    fetch('/api/home/contact', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formItem)
    })
      .then(() => {
        ShowMessage("Your message was sent! We'll be back to you shortly.");
      })
      .catch((error) => {
        ShowMessage('There was a problem sending your message. Please email us at team@hellonectarine.com');
        console.error('Error sending message.', error);
      });

    return false;
  };

  return (
    <div>
      <Helmet>
        <title>Nectarine - Contact Us</title>
      </Helmet>

      <Form className="contact-us" onSubmit={onSave}>
        <Row>
          {messageSentSection.show && (
            <Col md={6}>
              <h1>Thanks for Saying Hi!</h1>
              <p>{messageSentSection.text}</p>
            </Col>
          )}
          {!messageSentSection.show && (
            <Col md={6}>
              <h1>Say Hello to Nectarine</h1>

              <p>Send us a message below and we'll get back to you ASAP!</p>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input id="name" name="name" placeholder="Your Name" type="text" required />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input id="email" name="email" type="email" placeholder="your.name@example.com" required />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="message">Message</Label>
                    <Input id="message" type="textarea" name="message" placeholder="Your message here..." required />
                  </FormGroup>
                  <Button className="btn btn-primary">Contact Us!</Button>
                </Col>
              </Row>
            </Col>
          )}
          <Col md={6}>
            <img src={heroImage} className="hero center" alt="Nectarine Investing Experts Illustration" />
          </Col>
        </Row>
      </Form>
    </div>
  );
};
