import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Container, Row, UncontrolledTooltip } from 'reactstrap';
import { ApplicationRoutes } from '../../../../constants';
import useNavigationService from '../../../../hooks/UseNavigationService';
import useUrlHelper from '../../../../hooks/UseUrlHelper';
import AdvisorsApiService from '../../../../services/api/AdvisorsApiService';
import { DisclaimerRow } from '../../../DisclaimerRow/DisclaimerRow';
import DateHelper from '../../../Helpers/DateHelper';
import { useLoadingOverlayContext } from '../../../LoadingAnimations/LoadingOverlay/LoadingOverlayContext';
import { FilterResultsModal } from '../../../Modals/FilterResultsModal';
import { SortResultsDropDown } from '../../../SortResultsDropDown/SortResultsDropDown';
import NotFound from '../../Errors/NotFound/NotFound';
import './AdvisorsResults.scss';

export type AdvisorFilterSortOptions = 'price' | 'available';

export const sortOption_Price: AdvisorFilterSortOptions = 'price';
export const sortOption_Available: AdvisorFilterSortOptions = 'available';

async function populateAdvisors(urlState: string): Promise<Advisor_BasicDetails_ViewModel[]> {
  const query = new URLSearchParams(window.location.search);

  if (urlState) {
    query.set('state', urlState);
  }

  // Convert the query object to a string and build the full API endpoint
  const queryString = query.toString() ? `?${query.toString()}` : '';
  const data = await AdvisorsApiService.getAllAdvisorDetails(queryString);

  return data;
}

const filteredAttributesSessionKey = 'filteredAttributes';

export const AdvisorsResults: React.FC = () => {
  const { navigateTo_AdvisorsDetails, navigateTo_AdvisorsResults, navigateTo_Error } = useNavigationService();

  const { getQueryStringParameter } = useUrlHelper();

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlayContext();

  const [allAdvisors, setAllAdvisors] = useState<Advisor_BasicDetails_ViewModel[]>([]);
  const [filteredAdvisors, setFilteredAdvisors] = useState<Advisor_BasicDetails_ViewModel[]>([]);
  const [filteredAttributes, setFilteredAttributes] = useState<string[]>([]);
  const [noMatch, setNoMatch] = useState<boolean>(false);
  const [filterModal, setFilterModal] = useState<boolean>(false);
  const [allAttributes, setAllAttributes] = useState<Advisor_Attribute_ViewModel[]>([]);

  const { urlState, singleFilter } = useParams<string>();
  const now = new Date();

  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  let stateTitle = '';

  if (urlState) {
    stateTitle = urlState
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  useEffect(() => {
    setFilteredAttributes(getSavedFilteredAttributes());

    const loadData = async () => {
      try {
        const advisorsResult = await populateAdvisors(urlState ?? 'Texas');
        handleAdvisorsResponse(advisorsResult);
      } catch (error) {
        navigateTo_Error();
      }
    };
    loadData();

    // Function to handle popstate event
    const handlePopState = () => {
      setFilteredAttributes(getSavedFilteredAttributes());
    };

    // Event listener for popstate event
    window.addEventListener('popstate', handlePopState);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredAdvisors(getMatchingAdvisors(filteredAttributes, allAdvisors));
  }, [filteredAttributes, allAdvisors]);

  useEffect(() => {
    sessionStorage.setItem(filteredAttributesSessionKey, JSON.stringify(filteredAttributes));
    setFilteredAdvisors(getMatchingAdvisors(filteredAttributes, allAdvisors));
  }, [allAdvisors, filteredAttributes]);

  const toggleFilterModal = () => {
    setFilterModal(!filterModal);
  };

  function handleAdvisorsResponse(advisors: Advisor_BasicDetails_ViewModel[]) {
    if (advisors == null) {
      setNoMatch(true);
    } else {
      setAllAdvisors(advisors);
      setFilteredAdvisors(advisors);

      //Get the list of all attributes
      const allSet = new Set();
      const all: Advisor_Attribute_ViewModel[] = [];

      // Iterate through advisors and their items
      advisors.forEach((advisor) => {
        advisor.iCanHelpYouWith.forEach((item) => {
          if (!allSet.has(item.slug)) {
            allSet.add(item.slug);
            all.push(item);
          }
        });

        advisor.iEnjoyWorkingWith.forEach((item) => {
          if (!allSet.has(item.slug)) {
            allSet.add(item.slug);
            all.push(item);
          }
        });
      });

      setAllAttributes(all);
    }
  }

  function getSavedFilteredAttributes(): string[] {
    //First check if there's something in the URL in the filters parameter
    let filters = getQueryStringParameter<string>('filters');

    if (!filters && singleFilter) {
      filters = singleFilter;
    }

    if (filters) {
      const fa = filters.split(' ');
      return fa;
    }

    return [];
  }

  function toTitleCase(str: string) {
    return str
      .split(' ') // Split the string into an array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words back into a single string
  }

  function getTitleTag() {
    //If there's exactly one filtered attribute, return a custom title tag
    if (allAttributes && filteredAttributes && filteredAttributes.length == 1) {
      const attribute = allAttributes.find((item) => item.slug === filteredAttributes[0]);

      if (attribute) {
        return toTitleCase(attribute.title) + ' Fiduciary Flat-Fee Financial Advisors';
      }
    }

    return getTitle() + ' ' + getSubtitle();
  }

  function getTitle() {
    if (!allAdvisors || !stateTitle) {
      return 'Fiduciary Flat-Fee Financial Advisors';
    }

    return stateTitle + ' Fiduciary Flat-Fee Financial Advisors';
  }

  function getSubtitle() {
    if (!filteredAttributes || filteredAttributes.length == 0 || !allAttributes) {
      return '';
    }

    //Loop through allAttributes and get the titles of the selected attributes
    const filteredAttributesTitles = allAttributes.filter((item) => filteredAttributes.includes(item.slug)).map((item) => item.title);

    //Loop through filteredAttributeTitles and make them all Title Case
    filteredAttributesTitles.forEach((item, index) => {
      filteredAttributesTitles[index] = toTitleCase(item);
    });

    //Sort filteredAttributesTitles alphabetically
    filteredAttributesTitles.sort();

    return 'Specializing in ' + filteredAttributesTitles.join(', ');
  }

  //Remove anything after /advisors/XXXX
  function trimPathname(pathname: string) {
    const pathParts = pathname.split('/');

    if (pathParts.length < 2) return pathname;

    //State search should maintain the state name
    if (pathParts[1] == 's' && pathParts.length > 2) {
      return `/${pathParts[1]}/${pathParts[2]}`;
    }

    if (pathParts.length > 0) {
      return `/${pathParts[1]}`;
    }
    return pathname; // Return the original pathname if it doesn't match the pattern
  }

  function filtersSaved(event: React.FormEvent, incomingAdvisors: Advisor_BasicDetails_ViewModel[], incomingAttributes: string[]) {
    event.preventDefault();
    setFilterModal(false);

    setFilteredAdvisors(getMatchingAdvisors(incomingAttributes, allAdvisors));

    // setFilteredAdvisors(incomingAdvisors);
    setFilteredAttributes(incomingAttributes);

    //Update the URL to reflect the selected attributes, like ?filters=real-estate+retirement
    const existingSortValue = getQueryStringParameter<AdvisorFilterSortOptions>('sort') ?? 'available';

    navigateTo_AdvisorsResults(existingSortValue, incomingAttributes);

    return false;
  }

  function getMatchingAdvisors(selectedAttributes: string[], advisors: Advisor_BasicDetails_ViewModel[]) {
    //If no selected attributes, show all advisors
    if (!advisors || !selectedAttributes || selectedAttributes.length == 0) {
      return advisors;
    }

    const matchingAdvisors = advisors.filter((currAdvisior) => {
      return [...currAdvisior.iCanHelpYouWith, ...currAdvisior.iEnjoyWorkingWith].some((v) => selectedAttributes.includes(v.slug));
    });

    return matchingAdvisors;
  }

  function getMatchingAdvisorAttributes(advisor: Advisor_BasicDetails_ViewModel) {
    //If no selected attributes, show all advisors
    if (!filteredAttributes || !filteredAttributes.length) {
      return [...advisor.iCanHelpYouWith, ...advisor.iEnjoyWorkingWith];
    }

    return [...advisor.iCanHelpYouWith, ...advisor.iEnjoyWorkingWith].filter((v) => filteredAttributes.includes(v.slug));
  }

  function areAdvisorAttributesAPerfectMatchWithFilteredAttributes(advisor: Advisor_BasicDetails_ViewModel, selectedAttributes: string[]) {
    const advisorAttributes = [...advisor.iCanHelpYouWith, ...advisor.iEnjoyWorkingWith];
    return selectedAttributes.every((v) => advisorAttributes.some((m) => m.slug === v));
  }

  function getSortedAdvisors(filteredAdvisors: Advisor_BasicDetails_ViewModel[]) {
    //Get the sort order from the URL
    const sortValue = new URLSearchParams(window.location.search).get('sort') ?? 'available';

    const orderByInfo: {
      fn: (input: Advisor_BasicDetails_ViewModel) => boolean | number | string | Date;
      direction: 'asc' | 'desc';
    }[] = [];

    // We want to show advisors who best matched the filters, if they're set
    if (filteredAttributes.length) {
      orderByInfo.push({
        fn: (currAdvisor) => {
          const numberOfMatchingFilters = [...currAdvisor.iCanHelpYouWith, ...currAdvisor.iEnjoyWorkingWith].filter((v) =>
            filteredAttributes.includes(v.slug)
          ).length;

          // NOTE: We just order by whether the advisor matched filters, not on how many they matched
          return numberOfMatchingFilters ? 1 : 0;
        },
        direction: 'desc'
      });
    }

    if (sortValue === 'price') {
      orderByInfo.push({
        fn: (v) => v.hourlyRate,
        direction: 'asc'
      });

      // Put anyone without availability at the end of the list
      orderByInfo.push({
        fn: (v) => new Date(v.nextAvailability ?? new Date(2050, 0, 1)),
        direction: 'asc'
      });
    } else {
      // Put anyone without availability at the end of the list
      orderByInfo.push({
        fn: (v) => new Date(v.nextAvailability ?? new Date(2050, 0, 1)),
        direction: 'asc'
      });

      orderByInfo.push({
        fn: (v) => v.hourlyRate,
        direction: 'asc'
      });
    }

    orderByInfo.push({
      fn: (v) => v.reviewAverage,
      direction: 'desc'
    });

    // Sort by last name, then first
    orderByInfo.push({
      fn: (v) => `${v.lastName} ${v.firstName}`,
      direction: 'asc'
    });

    return orderBy(
      filteredAdvisors,
      orderByInfo.map((v) => v.fn),
      orderByInfo.map((v) => v.direction)
    );
  }

  function getCountOfAdvisorsWhoMatchFiltersPerfectly() {
    return filteredAdvisors.filter((v) => areAdvisorAttributesAPerfectMatchWithFilteredAttributes(v, filteredAttributes)).length;
  }

  function getCountOfAdvisorsWhoDoNotMatchFiltersPerfectly() {
    return filteredAdvisors.filter((v) => !areAdvisorAttributesAPerfectMatchWithFilteredAttributes(v, filteredAttributes)).length;
  }

  function getAdvisorsDisplayTemplate(advisorsType: 'perfect-match' | 'partial-match') {
    const arrayToDisplay =
      advisorsType === 'perfect-match'
        ? filteredAdvisors.filter((v) => areAdvisorAttributesAPerfectMatchWithFilteredAttributes(v, filteredAttributes))
        : filteredAdvisors.filter((v) => !areAdvisorAttributesAPerfectMatchWithFilteredAttributes(v, filteredAttributes));

    return (
      <Row className="advisor-results-row">
        {getSortedAdvisors(arrayToDisplay).map((advisor) => (
          <Col sm="4" className="advisor-results-cell" key={advisor.id}>
            <div className="advisor-image">
              <Link to={`/${ApplicationRoutes.Advisor}/${advisor.slug}`}>
                <img
                  src={advisor.profilePicUrl}
                  className="circle-image headshot"
                  alt={advisor.firstName + ' ' + advisor.lastName + ', ' + advisor.certifications}
                />
              </Link>
            </div>
            <div className="results-content">
              <h1 className="results-name">
                <Link to={`/${ApplicationRoutes.Advisor}/${advisor.slug}`}>
                  {advisor.firstName} {advisor.lastName}
                  {advisor.certifications !== null && advisor.certifications !== undefined && advisor.certifications !== '' ? ', ' : ''}
                  {advisor.certifications}
                </Link>
              </h1>
              <h2>{advisor.title}</h2>
              <div className="results-reviews">
                {advisor.reviewAverage > 0 ? (
                  <span id="AverageReviewTooltip">
                    <i className="fa-star fa-solid"></i> {advisor.reviewAverage.toFixed(1)} ({advisor.reviewCount})
                  </span>
                ) : (
                  <></>
                )}
                {!advisor.showReviews && advisor.reviewCount > 0 ? (
                  <span id={'CantShowReviewsTooltip-' + advisor.id}>
                    <i className="fa-star fa-solid"></i> <i className="fa-duotone fa-eye-slash"></i> ({advisor.reviewCount})
                    <UncontrolledTooltip target={'CantShowReviewsTooltip-' + advisor.id}>
                      {advisor.firstName} has received {advisor.reviewCount} review
                      {advisor.reviewCount > 1 && 's'} but {advisor.reviewCount > 1 ? 'they are' : 'it is'} hidden due to state regulations
                      not allowing the posting of testimonials
                    </UncontrolledTooltip>
                  </span>
                ) : (
                  <></>
                )}
                {advisor.eventCount > 0 ? (
                  <span id="EventCountTooltip">
                    <i className="fa-calendar fa-solid"></i>&nbsp; {advisor.eventCount}
                  </span>
                ) : (
                  <></>
                )}
                {new Date(advisor.createdAt) > threeMonthsAgo ? (
                  <span>
                    <i className="fa-star fa-solid light-blue"></i>&nbsp;Newly Added!
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <ul>
                {advisor.bio.split('\n').map((item, key) => (
                  <li key={key}>{item}</li>
                ))}
              </ul>

              {filteredAttributes.length ? (
                <div>
                  {filteredAttributes.length ? (
                    <>
                      {filteredAttributes.length > 1 &&
                      areAdvisorAttributesAPerfectMatchWithFilteredAttributes(advisor, filteredAttributes) ? (
                        <>
                          <i className={'fa-solid att-icon matches-all-filters-icon fa-circle-check'}></i>
                          <span className="ms-2">Matches All Filters</span>
                        </>
                      ) : (
                        <></>
                      )}

                      {getMatchingAdvisorAttributes(advisor).map((currAttribute) => (
                        <div key={currAttribute.slug}>
                          <i className={'fa-light att-icon results-att ' + currAttribute.iconTag}></i> {currAttribute.title}
                        </div>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
              <div>
                <span className="results-rate">${Math.round(advisor.hourlyRate).toLocaleString('en-US')}</span>
                <span className="results-product"> / one hour</span>
              </div>
              <div className="mt-3">
                <button className="btn btn-light results-btn center" onClick={() => navigateTo_AdvisorsDetails(advisor.slug)}>
                  <span className="view-profile">View Profile</span>

                  {advisor.nextAvailability ? (
                    new Date(advisor.nextAvailability) > now ? (
                      <span className="next-available">
                        <br />
                        Next Available: {DateHelper.casualDateFormat(advisor.nextAvailability)}
                      </span>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </button>
              </div>
            </div>
          </Col>
        ))}

        <span id="AverageReviewTooltip" className="d-none">
          <i className="fa-star fa-solid"></i>
        </span>
        <span id="EventCountTooltip" className="d-none">
          <i className="fa-calendar fa-solid"></i>
        </span>
        <UncontrolledTooltip target="EventCountTooltip">Number of meetings this advisor has hosted on Nectarine</UncontrolledTooltip>
        <UncontrolledTooltip target="AverageReviewTooltip">Average review score left for this advisor</UncontrolledTooltip>
      </Row>
    );
  }

  return (
    <>
      <Helmet>
        <title>{getTitleTag()}</title>
      </Helmet>
      <Container>
        <Row>
          <Row className="match-title">
            <h1>{getTitle()}</h1>
            <h2>{getSubtitle()}</h2>
          </Row>
          {noMatch ? <NotFound /> : <></>}
          {/* Loading section whle advisors are being loaded */}
          {!noMatch && !allAdvisors.length && (
            <>
              <Row>
                <Col xs="12" className="filters-row d-flex justify-content-center center mb-3">
                  <Skeleton height={38} width={86} borderRadius={25} /> <Skeleton height={38} width={188} borderRadius={25} />
                </Col>
              </Row>
              <Row>
                <Col className="center mb-5">
                  Showing <Skeleton width={20} count={1} inline={true} /> matching advisors.
                </Col>
              </Row>

              <Row className="advisor-results-row">
                {Array.from({ length: 3 }).map((_, index) => (
                  <Col key={index} sm="4" className="advisor-results-cell">
                    <div className="advisor-image">
                      <Skeleton circle={true} height={160} width={160} />
                    </div>
                    <div className="results-content">
                      <h1 className="results-name">
                        <Skeleton width={250} />
                      </h1>
                      <h2>
                        <Skeleton width={150} />
                      </h2>
                      <div className="results-reviews">
                        <Skeleton width={200} />
                      </div>
                      <ul>
                        <li>
                          <Skeleton width={100} />
                        </li>
                        <li>
                          <Skeleton width={175} />
                        </li>
                        <li>
                          <Skeleton width={150} />
                        </li>
                      </ul>
                      <Skeleton width={100} height={25} />
                      <Skeleton className="center mt-2" height={68} width={300} borderRadius={34} />
                    </div>
                  </Col>
                ))}
              </Row>
            </>
          )}
          {filteredAdvisors.length ? (
            <>
              <Row>
                <Col xs="12" className="filters-row d-flex justify-content-center center mb-3">
                  <Button color="light" onClick={toggleFilterModal}>
                    <i className="fa-duotone fa-sliders"></i>

                    <span className="ms-2">
                      Filters
                      {filteredAttributes && filteredAttributes.length > 0 ? (
                        <span>
                          {' '}
                          (<strong>{filteredAttributes.length}</strong>)
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                  </Button>{' '}
                  <FilterResultsModal
                    show={filterModal}
                    toggle={toggleFilterModal}
                    advisors={allAdvisors}
                    selectedAttributes={filteredAttributes}
                    getMatchingAdvisors={getMatchingAdvisors}
                    areAdvisorAttributesAPerfectMatchWithFilteredAttributes={areAdvisorAttributesAPerfectMatchWithFilteredAttributes}
                    onSave={filtersSaved}
                  />
                  <SortResultsDropDown />
                </Col>
              </Row>
              <Row>
                <Col className="center mb-5">
                  {filteredAttributes.length ? (
                    <>
                      Showing <strong>{filteredAdvisors.length}</strong> matching advisor{filteredAdvisors.length != 1 ? 's' : ''}.
                    </>
                  ) : (
                    <>
                      Showing all {filteredAdvisors.length} advisor{filteredAdvisors.length != 1 ? 's' : ''}
                    </>
                  )}
                </Col>
              </Row>

              {getCountOfAdvisorsWhoMatchFiltersPerfectly() > 0 ? (
                <>
                  {filteredAttributes.length > 1 ? (
                    <h2 className="mb-3 mb-4 text-center">
                      Advisors Matching All Filters ({getCountOfAdvisorsWhoMatchFiltersPerfectly()}){' '}
                    </h2>
                  ) : (
                    <></>
                  )}

                  {getAdvisorsDisplayTemplate('perfect-match')}

                  {/* On mobile, display a horizontal line, to break up the visual continuity, so the user is aware they're in a new "section" of data */}
                  <div className="d-md-none">
                    <hr />
                    <div className="mb-5"></div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {getCountOfAdvisorsWhoDoNotMatchFiltersPerfectly() > 0 ? (
                <>
                  {filteredAttributes.length > 1 ? (
                    <h2 className="mt-3 mb-4 text-center">
                      Advisors Matching Some Filters ({getCountOfAdvisorsWhoDoNotMatchFiltersPerfectly()})
                    </h2>
                  ) : (
                    <></>
                  )}
                  {getAdvisorsDisplayTemplate('partial-match')}
                </>
              ) : (
                <></>
              )}

              <DisclaimerRow />
            </>
          ) : (
            <></>
          )}
        </Row>
      </Container>
    </>
  );
};
