import React, { useEffect, useState } from 'react';
import { ApplicationRoutes, UserLogoutActions } from '../../constants';
import { PageLayout } from './../Pages/Layout/PageLayout/PageLayout';
import AuthorizeService, { AuthenticationResultStatus } from './AuthorizeService';

interface LogoutProps {
  action: string;
}

const Logout: React.FC<LogoutProps> = ({ action }) => {
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isReady, setIsReady] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const handleLogout = async () => {
      switch (action) {
        case UserLogoutActions.Logout:
          if (window.history.state?.usr?.local) {
            await logout(getReturnUrl());
          } else {
            setMessage('The logout was not initiated from within the page.');
            setIsReady(true);
          }
          break;
        case UserLogoutActions.LogoutCallback:
          await processLogoutCallback();
          break;
        case UserLogoutActions.LoggedOut:
          setMessage('You successfully logged out!');
          setIsReady(true);
          break;
        default:
          throw new Error(`Invalid action '${action}'`);
      }

      await populateAuthenticationState();
    };

    handleLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loggedOutPage = () => (
    <PageLayout>
      <div className="logout-page">
        <h1>Thanks for saying Hello!</h1>
        <p>You have successfully logged out.</p>
      </div>
    </PageLayout>
  );

  const logout = async (returnUrl: string) => {
    const state = { returnUrl };
    const isauthenticated = await AuthorizeService.isAuthenticated();
    if (isauthenticated) {
      const result = await AuthorizeService.signOut(state);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          await navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          setMessage((result as any).message);
          break;
        default:
          throw new Error('Invalid authentication result status.');
      }
    } else {
      setMessage('You successfully logged out!');
    }
  };

  const processLogoutCallback = async () => {
    const url = window.location.href;
    const result = await AuthorizeService.completeSignOut(url);

    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        throw new Error('Should not redirect.');
      case AuthenticationResultStatus.Success:
        await navigateToReturnUrl(getReturnUrl((result as any).state));
        break;
      case AuthenticationResultStatus.Fail:
        setMessage((result as any).message);
        break;
      default:
        throw new Error('Invalid authentication result status.');
    }
  };

  const populateAuthenticationState = async () => {
    const isAuthenticated = await AuthorizeService.isAuthenticated();
    setAuthenticated(isAuthenticated);
    setIsReady(true);
  };

  const getReturnUrl = (state?: { returnUrl?: string }) => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get('returnUrl');
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
    }
    return (state && state.returnUrl) || fromQuery || `${window.location.origin}${ApplicationRoutes.AuthenticationRoutes.LoggedOut}`;
  };

  const navigateToReturnUrl = (returnUrl: string) => {
    window.location.replace(returnUrl);
  };

  if (!isReady) {
    return <div></div>;
  }

  if (message) {
    return loggedOutPage();
  }

  switch (action) {
    case UserLogoutActions.Logout:
      return <div>Processing logout</div>;
    case UserLogoutActions.LogoutCallback:
      return <div>Processing logout callback</div>;
    case UserLogoutActions.LoggedOut:
      return loggedOutPage();
    default:
      throw new Error(`Invalid action '${action}'`);
  }
};

export default Logout;
