//@ts-check
﻿import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { ApplicationRoutes } from '../../../../constants';
import HttpHelper from '../../../Helpers/HttpHelper';
import DateHelper from '../../../Helpers/DateHelper';
import LoadingSpinner from '../../../LoadingAnimations/LoadingSpinner/LoadingSpinner';

async function populateRecordings() {
  const data = await HttpHelper.get('api/admin/recordings');
  // console.log('Data:', data);
  return data;
}

export const AdminRecordings = () => {
  const [recordings, setRecordings] = useState(null);

  React.useEffect(() => {
    populateRecordings().then((a) => {
      setRecordings(a);
    });
  }, []);

  const onDelete = (e, rec) => {
    e.preventDefault();

    const isConfirmed = window.confirm("Are you sure you want to delete this recording? This is permanent and can't be undone.");

    if (!isConfirmed) {
      return false;
    }

    const updatedRecordings = [...recordings];
    const index = updatedRecordings.indexOf(rec);
    if (index !== -1) {
      updatedRecordings.splice(index, 1);
    }

    // console.log('deleting', index, rec, updatedRecordings);

    HttpHelper.delete('api/zoom/recording/' + rec.id)
      .then(() => {
        setRecordings(updatedRecordings);
        alert('Recording deleted successfully.');
      })
      .catch((error) => {
        alert(error);
        console.error('Unable to delete item.', error);
      });

    return false;
  };

  return (
    <Container>
      <Helmet>
        <title>Nectarine - Recordings</title>
      </Helmet>
      <Row>
        <Col>
          <Link to={`/${ApplicationRoutes.AdminRoutes.Root}`}>
            <i className="fa-regular fa-arrow-left back-arrow"></i> Back
          </Link>
          <h1>View All Recordings</h1>

          {!recordings && <LoadingSpinner message="Loading..." />}
          {recordings && (
            <table className="table">
              <thead>
                <tr>
                  <th>Start Time</th>
                  <th>Duration</th>
                  <th>Event</th>
                  <th>Host Email</th>
                  <th>Size</th>
                  <th>Created At</th>
                  <th>Status</th>
                  <th>Links</th>
                  <th className="text-danger">Danger</th>
                </tr>
              </thead>
              <tbody>
                {recordings.map((rec) => (
                  <tr key={rec.id}>
                    <td>
                      <Link to={`/${ApplicationRoutes.AdminRoutes.Recordings_Full}/${rec.id}/edit`}>
                        {DateHelper.mediumDateFormat(rec.startTime)}
                      </Link>
                    </td>
                    <td>{rec.durationInMinutes} minutes</td>
                    <td>
                      {rec.event && (
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Events_Full}/${rec.event.id}/edit`}>
                          {rec.event.advisorEmail} + {rec.event.consumerEmail}
                        </Link>
                      )}
                      {!rec.event && <strong className="red">No Linked Event!</strong>}
                    </td>
                    <td>{rec.hostEmail}</td>
                    <td>{(rec.fileSize / 1024.0 / 1024.0).toFixed(1)}MB</td>
                    <td>{DateHelper.mediumDateFormat(rec.createdAt)}</td>
                    <td>{rec.status}</td>
                    <td>
                      <a href={rec.downloadUrl}>Download</a>, <a href={rec.playUrl}>Play</a>, <a href={rec.url}>S3</a>
                    </td>
                    <td>
                      <Button color="danger" onClick={(e) => onDelete(e, rec)}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
    </Container>
  );
};
