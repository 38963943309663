import { CommonApiBadRequestErrors, CommonErrorMessages } from '../../constants';

export default class ErrorsHelper {
  public static getErrorMessageFromErrorObject(errorObject: unknown): string {
    if (typeof errorObject === 'string') {
      switch (errorObject) {
        case 'Failed to fetch':
          return CommonErrorMessages.General;

        case CommonApiBadRequestErrors.NotLoggedIn:
          return CommonErrorMessages.NotLoggedIn;

        default:
          return errorObject;
      }
    }

    return CommonErrorMessages.General;
  }
}
