import HttpHelper from '../../components/Helpers/HttpHelper';
import { ErrorLogService } from '../ErrorLogService';
import ApiServiceBase from './ApiServiceBase';

export default class UsersApiService extends ApiServiceBase {
  private static className = 'UsersApiService';

  public static async getBasicUserInformation(userId: string): Promise<User_BasicDetails_ViewModel> {
    try {
      const result = await this.makeApiCall<User_BasicDetails_ViewModel>(() => HttpHelper.get(`api/home/users/${userId}`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getBasicUserInformation()`,
        `An error occurred while loading user information for user ID '${userId}'.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async agreeToPlanTerms(advisorUserId: string, planType: 'TheHour' | 'ThePlan', stateAbbreviation: string): Promise<void> {
    try {
      const editModel = {
        stateAbbreviation,
        product: planType
      };
      await this.makeApiCall(() => HttpHelper.post(`api/users/agreetoterms/${advisorUserId}`, editModel));
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - agreeToPlanTerms()`,
        `An error occurred while agreeing to terms for advisor ID: ${advisorUserId}`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }
}
